@use '../../styles/colors' as c;

.About {
  margin-top: 36px;

  h2 {
    color: var(--secondary-color);
    font-size: inherit;
    font-weight: 600;
    text-transform: lowercase;
    margin: 0 0 10px;
  }

  & > section {
    margin-bottom: 22px;
  }

  .about-link {
    position: relative;
    z-index: 1;

    color: var(--secondary-color);
    text-decoration: none;

    &:hover::before {
      content: " ";
      left: 50%;
      top: 50%;
      width: calc(100% + 0.30rem);
      height: calc(100% + 0.30rem);
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: -1;
    }
  }

  .ntnu-link {
    &::selection {
      background: c.$ntnu-blue;
      color: white;
    }

    &:hover {
      color: white;

      &::before {
        background: c.$ntnu-blue;
      }
    }
  }

  .variant-link {
    &::selection {
      background: c.$variant-gray;
    }

    &:hover {
      color: white;

      &::before {
        background: c.$variant-gray;
      }
    }
  }

  .samfundet-link {
    &::selection {
      background: c.$samfundet-red;
      color: white;
    }

    &:hover {
      color: white;

      &::before {
        background: c.$samfundet-red;
      }
    }
  }
}

